import { useCookie } from "@/utils/Session"
import Link from "next/link"
import { useEffect, useState } from "react"
import { DessertIcon } from "./DessertIcon"

const HeaderNav = () => {
  const [isLoggedIn,,] = useCookie('loggedIn')
  const [hamburger, setHamburger] = useState(false)

  const [loggedInShow, setLoggedInShow] = useState('')
  const [loggedOutShow, setLoggedOutShow] = useState('hidden')

  useEffect(() => {
    if (isLoggedIn()) {
      setLoggedInShow('')
      setLoggedOutShow('hidden')
    } else {
      setLoggedInShow('hidden')
      setLoggedOutShow('')
    }
  }, [])

  function toggleHamburger() {
    setHamburger(!hamburger)
  }

  return (
    <>
      <div className="hidden md:block bg-blue-900 py-3">
        {/* <DessertIcon icon="cake-logo" className="absolute left-1 top-0" size="medium" /> */}
        <ul className="flex flex-wrap text-xl w-2/3 m-auto">
          <li className={`mr-10 ml-4 ${loggedOutShow}`}>
            <Link 
              href="/"
              className="text-sky-50 font-bold border-none hover:text-blue-600"
            >
                Home
            </Link>
          </li>
          <li className={`mr-10 ${loggedInShow}`}>
            <Link 
              href="/account/"
              className="text-sky-50 font-bold border-none hover:text-blue-600"
            >
                Account
            </Link>
          </li>
          {false && (
            <li className={`mr-10 ${loggedOutShow}`}>
              <Link 
                href={"/about/"}
                className="text-sky-50 font-bold border-none hover:text-blue-600"
              >
                  How it Works
              </Link>
            </li>
          )}
          <li className={`mr-10 ${loggedInShow}`}>
            <Link 
              href={"/connected-apps/"}
              className="text-sky-50 font-bold border-none hover:text-blue-600"
            >
                Connected Apps
            </Link>
          </li>
          <li className={`mr-10 ${loggedOutShow}`}>
            <Link 
              href={"/pricing/"}
              className="text-sky-50 font-bold border-none hover:text-blue-600"
            >
                Pricing
            </Link>
          </li>
          <li className="mr-10">
            <Link 
              href={"/blog/"}
              className="text-sky-50 font-bold border-none hover:text-blue-600"
            >
                Guides
            </Link>
          </li>
          <li>
            &nbsp; {/* This is required or else the next link overwrites styles */}
          </li>
          <li className="mr-10 grow text-right">
            <Link 
              href={"/feature-request/"}
              className="text-sky-50 font-bold italic border-none hover:text-blue-600"
            >
                Other Products
            </Link>
          </li>
        </ul>
      </div>

      <div className="p-3 space-y-2 bg-gray-600 shadow md:invisible flex items-center justify-end" onClick={() => toggleHamburger()}>
        <p className='inline-block text-white font-semibold m-3 text-md text-gray-600'>
          
        </p>
        <img 
          src={`/assets/images/hamburger_icon.svg`} 
          alt="Menu" 
          className="h-10 opacity-40 inline-block"
        />
      </div>

      <div className={hamburger ? "" : "hidden"}>
        <div className="p-4 space-y-2 bg-gray-200 shadow text-lg md:invisible">
          <p className={ loggedOutShow }><Link href="/login/"  data-umami-event={"Clicked login hamburger"}>Login</Link></p>
          <p className={ loggedOutShow }><Link href="/signup/" data-umami-event={"Clicked Get Started hamburger"}>Create Account</Link></p>
          <p><Link href="/"        data-umami-event={"Clicked home hamburger"}>Home</Link></p>
          <p className={ loggedInShow }><Link href="/account/">Account</Link></p>
          <p><Link href="/about-us/"  data-umami-event={"Clicked about us hamburger"}>About Us</Link></p>
          {/* <p><Link href="/about/"  data-umami-event={"Clicked How it Works hamburger"}>How it Works</Link></p> */}
          <p className={ loggedInShow }><Link href="/connected-apps/"  data-umami-event={"Clicked connected apps hamburger"}>Connected Apps</Link></p>
          <p><Link href="/pricing/"  data-umami-event={"Clicked pricing hamburger"}>Pricing</Link></p>
          <p><Link href="/blog/"  data-umami-event={"Clicked blog hamburger"}>Secret Sauce</Link></p>
          <p><Link href="/feature-request/"  data-umami-event={"Clicked another integration hamburger"}>Need another integration?</Link></p>
        </div>
      </div>
    </>
  )
}

export default HeaderNav