/* eslint-disable react/no-unescaped-entities */
import { Meta } from '@/layout/Meta'
import { Main } from '@/templates/Main'
import { AppConfig } from '@/utils/AppConfig'
import { DessertIcon } from '@/utils/DessertIcon'
import { Cta } from '@/utils/Cta'
import Link from 'next/link'
import { useRouter } from 'next/router'
import ReadonlyFieldDescription from './components/readonly-field-description'

const Index = () => {
  const router = useRouter()
  
  return (
    <Main
      meta={
        <Meta
          title="Dessert Data - Neatly Organized Performance Data"
          description="Export your digital advertising data into a SQL database."
        />
      }
    >
      <div className="text-center mt-4 mb-4">
        <h1 className="font-bold text-3xl mb-5 md:text-3xl text-blue-800">
          Understand Your Ad Performance
        </h1>
        <p className="ml-2 text-left">
          { AppConfig.company } connects and organizes all of your advertising metrics so you 
          can experiment across marketing channels without complexity.
        </p>
        <p className="ml-2 text-left">
          Export your data into BigQuery or Google Sheets to easily create cross channel reports.
        </p>.
        <img src={`${router.basePath}/assets/images/January-Spend.png`} className="block mx-auto border" />
      </div>
      <Cta className="mb-20">
        GET STARTED FREE
      </Cta>
      <div>
        <h2 className="font-bold mb-3 text-2xl text-center md:text-3xl text-blue-800">Data Served How You Want</h2>
        <p className="text-left mx-auto mt-0">
          Choose the metrics you want and we handle the mappings and calculations.
        </p>
        <ReadonlyFieldDescription
          label='Month'
          description='The month associated with each metric.'
        />
        <ReadonlyFieldDescription
          label='Campaign Name'
          description='The name of the campaign.'
        />
        <ReadonlyFieldDescription
          label='Spend'
          description='The cost associated with the record.'
        />
        <ReadonlyFieldDescription
          label='Revenue'
          description='The revenue associated with the record.'
        />
        <ReadonlyFieldDescription
          label='Impressions'
          description='The number of times your ad was shown.'
        />
        <div className='text-center mt-3 italic'>
          ... and many more!
        </div>

      </div>
      <div className='mt-16'>
        <h2 className="font-bold mb-3 text-2xl text-center md:text-3xl text-blue-800">Set it and forget it</h2>
        <p className="text-left mx-auto mt-0">
          DessertData automatically pulls your metrics several times a day so your data is always fresh.
        </p>
      </div>
      <Cta className="mb-20">
        SIGN UP RISK FREE
      </Cta>
      <div className="mt-10">
        <h3 className="font-bold text-2xl text-center md:text-3xl">Support for the following advertisers</h3>
        <div className="text-center w-4/5 mx-auto mt-0">
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://developers.google.com/google-ads/api/docs/reporting/overview">
              Google Ads
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://learn.microsoft.com/en-us/advertising/reporting-service/campaignperformancereportrequest?view=bingads-13">
              Bing Ads
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://developers.facebook.com/docs/marketing-api/reporting/">
              Facebook Ads
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://business-api.tiktok.com/portal/docs?id=1740302848100353">
              TikTok Ads
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://learn.microsoft.com/en-us/linkedin/marketing/integrations/ads-reporting/ads-reporting?view=li-lms-2023-08&tabs=http">
              LinkedIn Ads
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://developers.cj.com/graphql/reference/Commission%20Detail">
              Commission Junction
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link target="_blank" href="https://integrations.impact.com/impact-publisher/reference/campaigns-overview">
              Impact Radius
            </Link>
          </span>
          <span className="inline-block mx-5">
            <Link href="/feature-request">Need something else?</Link>
          </span>
        </div>
      </div>
      <div className="text-left mt-10">
        <h2 className="font-bold text-center text-2xl pb-4 md:text-3xl text-blue-800">White Glove Service <DessertIcon/></h2>
        We provide dedicated technical support to help you with anything you need to get connected to your data.
        <p className="italic text-center font-bold text-teal-700 mt-0">At no additional cost!</p>
      </div>
      <div className="text-left mt-10">
        <h2 className="font-bold text-center text-2xl mt-5 mb-4 md:text-3xl text-blue-800">Is there a FREE tier? <DessertIcon/></h2>
        <strong>Absolutely!</strong> On-Demand syncs are always free.  No credit card required!
        <br />
      </div>

      <div className="w-full text-center mt-4">
        <label className="block md:inline-block mx-auto md:mx-5 w-28">
          <DessertIcon icon="candy" size="big" />
          <p className="-mt-3 text-base leading-5">All your ads data</p>
        </label>
        <label className="block md:inline-block mx-auto md:mx-5 w-28">
          <DessertIcon icon="bow" size="big" />
          <p className="-mt-3 text-base leading-5">Neatly organized</p>
        </label>
        <label className="block md:inline-block mx-auto md:mx-5 w-28">
          <DessertIcon icon="cake" size="big" />
          <p className="-mt-2 text-base leading-5">Served how you want it</p>
        </label>
      </div>  
      <Cta>
        GET STARTED FREE
      </Cta>
    </Main>
  )
}

export default Index
