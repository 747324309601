import { ReactNode } from 'react'

import { useRouter } from 'next/router'

import Link from 'next/link'

import { AppConfig } from '@/utils/AppConfig'
import { useCookie } from '@/utils/Session'

import { useSessionContext, ISessionContext } from '../pages/_app'
import { Footer } from '@/layout/Footer'
import HeaderNav from '@/utils/Header'

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
};

const Main = (props: IMainProps) => {
  const router = useRouter();
  const [isLoggedIn,,deleteIsLoggedIn] = useCookie('loggedIn')
  const [,,deleteSessionId] = useCookie('sessionId')
  const [sessionEmail] = useSessionContext() as ISessionContext

  const handleLogout = () => {
    deleteSessionId()
    deleteIsLoggedIn()
    router.push('/')
  }

  return (
    <>
    <HeaderNav />

    <div className="antialiased w-full text-gray-700 px-1 md:pt-1 max-w-3xl m-auto">
      {props.meta}

      <img
        className="hidden w-full object-cover -z-30 absolute top-0 left-0"
        src={`${router.basePath}/assets/images/bing-bot.jpeg`}
        alt="Database watercolor"
      />

      <div className="max-w-screen-md mx-auto bg-zinc-50 pt-0 p-7">
        <div className="border-b border-gray-300">
          <div className="pt-4 pb-8">
          {/* <div className="pt-4 m:pt-16 pb-8"> */}
            <div className="mb-8 m:pb-0 float-right hidden md:block">
              {!isLoggedIn() ? (
                <div>
                  <Link href="/login/">Login</Link>&nbsp;|&nbsp;
                  <Link href="/signup/">New Account</Link>
                </div>
              ) : (
                <div>
                  <div>Welcome {sessionEmail} | <a href="" onClick={() => handleLogout()}>Logout</a></div>
                </div>
              )}
            </div>
            <div className='text-center md:text-left'>
              <div className="font-bold text-4xl md:text-5xl text-blue-800 -ml-3">
                <img src={`${router.basePath}/assets/images/dessertdata/cake1.png`} className="h-12 inline-block" />
                {AppConfig.title}
              </div>
              <div className="text-xl md:text-lg ml-1 italic -mt-2">{AppConfig.description}</div>
            </div>
          </div>
        </div>

        <div className="py-5 text-xl content">{props.children}</div>

        <Footer />
      </div>
    </div>
    </>
  )
}

export { Main }
